import React from 'react'

function PageNotFound() {

    return (
        <>
            <div>
                <h1>404 - Page Not Found!</h1>
            </div>
        </>

    )
}

export default PageNotFound;