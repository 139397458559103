import React from 'react';

const GoogleLoginButton = () => {
    const handleLogin = () => {
        console.log("Backend URL:", process.env.REACT_APP_SERVER_AUTH_URL);
        // Redirect the user to the backend's Google OAuth endpoint
        window.location.href = process.env.REACT_APP_SERVER_AUTH_URL;
    };

    return (
        <div className="google-login-button">
            <button onClick={handleLogin}>
                Sign in with Google
            </button>
        </div>
    );
};

export default GoogleLoginButton;
