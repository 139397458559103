
import React, { useState, useEffect } from 'react';
import GoogleLoginButton from '../Resources/GoogleLoginButton';


const Login = () => {

    const [pingMessage, setPingMessage] = useState('');

    useEffect(() => {
        const fetchPing = async () => {
            try {
                const pingURL = process.env.REACT_APP_PING_TEST;
                const response = await fetch(pingURL, {
                    method: 'GET', // Ensure this is the correct HTTP method
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    mode: "cors",
                    keepalive: "true"
                });
                const data = await response.json();
                setPingMessage(data.message);
                console.log(pingURL);
            } catch (error) {
                console.error('Error fetching ping:', error);
            }
        };

        fetchPing();
    }, []);


    return (
        <>
            <div>
                <GoogleLoginButton/>
            </div>
            <h1>Ping Response:</h1>
            <p>{pingMessage}</p>
        </>
    )
}

export default Login