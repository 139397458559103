import React, { useEffect } from 'react';
import '../Styles/Fireworks.css';

const Fireworks = () => {
    useEffect(() => {
        const canvas = document.getElementById('fireworksCanvas');
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        let particles = [];
        const colors = [
            '#ff0044'
            , '#ffbb00'
            , '#00ff99'
            , '#0033ff'];

        // Function to create a firework
        const createFirework = (x, y) => {
            const count = 100;
            for (let i = 0; i < count; i++) {
                particles.push({
                    x: x,
                    y: y,
                    speed: Math.random() * 6 , // Random speed
                    angle: Math.random() * Math.PI * 2, // Random direction
                    color: colors[Math.floor(Math.random() * colors.length)],
                    alpha: 1, // Start fully opaque
                    life: 1000 // Lifespan of particles
                });
            }
        };

        // Function to update particle properties
        const updateParticles = () => {
            particles = particles.filter(p => p.alpha > 0.15); // Filter out particles with alpha < 0.05
            particles.forEach(p => {
                p.x += Math.cos(p.angle) * p.speed;
                p.y += Math.sin(p.angle) * p.speed;
                p.speed *= 0.98; // Slow down over time
                p.alpha = Math.max(p.alpha - .01 * .99, 0); // Fade out over time
                p.life--;
            });
            particles = particles.filter(p => p.life > 0);
        };

        // Function to draw particles with updated properties
        const drawParticles = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            particles.forEach(p => {
                ctx.beginPath();
                ctx.rect(p.x, p.y, 3, 3);
                ctx.fillStyle = p.color;
                ctx.globalAlpha = p.alpha; // Set alpha for transparency
                ctx.fill();
            });
            ctx.globalAlpha = 1; // Reset alpha for other drawings
        };

        // Main animation loop
        const animate = () => {
            updateParticles();
            drawParticles();
            requestAnimationFrame(animate);
        };

        // Trigger multiple fireworks at random intervals and positions
        const triggerFireworks = () => {
            createFirework(
                Math.random() * canvas.width,
                Math.random() * canvas.height
            );createFirework(
                Math.random() * canvas.width,
                Math.random() * canvas.height
            );createFirework(
                Math.random() * canvas.width,
                Math.random() * canvas.height
            );
            const interval1 = setInterval(() => {
                createFirework(
                    Math.random() * canvas.width,
                    Math.random() * canvas.height
                );
            }, Math.random() * 3000 );
            setTimeout(() => clearInterval(interval1), 10000);

            const interval2 = setInterval(() => {
                createFirework(
                    Math.random() * canvas.width,
                    Math.random() * canvas.height
                );
            }, Math.random() * 3000 );
            setTimeout(() => clearInterval(interval2), 10000);

            const interval3 = setInterval(() => {
                createFirework(
                    Math.random() * canvas.width,
                    Math.random() * canvas.height
                );
            }, Math.random() * 3000 ); // Random fireworks between 0 and 3 seconds

            // Optional: Stop triggering fireworks after 10 seconds
            setTimeout(() => clearInterval(interval3), 10000);
        };

        triggerFireworks();
        animate();

        // Clean up when component unmounts
        return () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        };
    }, []);

    return (
        <div className="fireworks-container">
            <canvas id="fireworksCanvas" width="100vw" height="100vh"></canvas>
        </div>
    );
};

export default Fireworks;
