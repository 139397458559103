import axios from "axios";

const ContactMe = () => {

    const getUsers = () => {
        let url = process.env.REACT_APP_BACKEND_SERVER_API + "/download/userDownload";
        console.log("getUsers URL:  " + url);
        axios.get(url)
            .then(response => console.log(response.data))
            .catch(error => console.error('Error:', error));
    }

    const createUser = () => {

        // generate unique user ID
        // format is as follows:
        // z + YYYYmmDDhhMM + 8 randomly generated digits, to help ensure unique value
        let currentDate = new Date();
        let date = currentDate.toISOString()
            .replaceAll("-","")
            .replaceAll(":","")
            .replaceAll(".","")
            .replaceAll("T","")
            .replaceAll("Z","")
            .toString()
            .substring(0, 12);
        let rand = "";
        for(let i = 0; i <8; i++){
            rand += Math.floor(Math.random()* 10).toString();
        }
        let zID = "z" + date + rand;

        console.log("zID:  " + zID);

        let randUserName = "test" + rand;
        let randEmail = "test" + rand + "@test.com";

        // create user
        let url = process.env.REACT_APP_BACKEND_SERVER_API + "/upload/createUser";
        axios.post(url, {
            person_id: zID,
            first_name: 'First',
            last_name: 'Test',
            email: randEmail,
            username: randUserName
        })
            .then(response => console.log(response.data))
            .catch(error => console.error('Error: ', error));
    }


    return (
        <div>
            <p>
                    Test the download:
                    <button onClick={getUsers}>find all users</button>
            </p>
            <p>
                Test the upload:
                <button onClick={createUser}>create new user</button>
            </p>
        </div>
)
}

export default ContactMe