import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Delete the authToken cookie by setting its expiration date to the past
        document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";

        // Optionally redirect the user to the login page or another page
        navigate('/');
        window.location.reload()
    };

    return (
        <div>
            <h1>Logout</h1>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Logout;
