import React, { useState } from 'react';
import infoCircle from '../Images/info-circle.png';
import '../Styles/InfoBubble.css'; // Include custom CSS for styling

function InfoBubble({text}) {
    const [show, setShow] = useState(false);

    return (
        <div className="info-bubble-container">
            <img src={infoCircle}
                 alt="info-circle"
                 className="info-circle"
                 onMouseEnter={() => setShow(true)}
                 onMouseLeave={() => setShow(false)}
            />
            {show && (
                <div className="info-bubble">
                    {text}
                </div>
            )}
        </div>
    );
}

export default InfoBubble;