// CallbackPage.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CallbackPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token) {
            // Store the token in a cookie with a 1-week expiration
            const date = new Date();
            date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // 1 week
            const expires = "; expires=" + date.toUTCString();
            document.cookie = "authToken=" + token + expires + "; path=/";

            // Clear the URL and redirect to the home page
            navigate('/', { replace: true });
        } else {
            // Handle error
            console.error('No token found');
        }
    }, [location, navigate]);

    return (
        <>
            <div>Authenticating...</div>
        </>
    );
};

export default CallbackPage;
