import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';

const Home = () => {

    const [modalState, setModalState] = useState(false);
    const toggleModal = () => {
        setModalState(!modalState);
    }

    const navigate = useNavigate();

    const AboutMe = "Wow, glad you're interested to learn more!  \n \n" +
    "My name is Kevin Zookski, and I have great enthusiasm for both product management and development. \n" +
        "I have over 10 years of experience in the industry and extensive knowledge of SaaS, Database Management, and SAP. \n" +
        "I find joy in applying what I know to new, challenging problems.";

    return (
        <div className="home-container">
            <header className="home-header">
                <h1>Welcome to My Portfolio</h1>
                <p>Hi, I'm Kevin. I'm a product owner, manager, and software and website developer.</p>
            </header>
            <div className="home-content">
                <div className="card" onClick={toggleModal}>
                    <h2>About Me</h2>
                    <p>Click to learn more about me</p>
                </div>
                <div className="card" onClick={() => navigate('/Projects')}>
                    <h2>Projects</h2>
                    <p>Check out my recent work</p>
                </div>
                <div className="card" onClick={() => navigate('/ContactMe')}>
                    <h2>Contact</h2>
                    <p>Get in touch with me</p>
                </div>
            </div>

            {modalState && (
                <div className="modal-overlay" onClick={toggleModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>About Me</h2>
                        <p>{AboutMe}</p>
                        <button onClick={toggleModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
