// Core Functionality
import './App.css';
import React from "react";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import {UserContextProvider} from './Resources/UserContext.js';
import UserDetails from './Resources/UserDetails';

// Pages
import Home from './Pages/Home';
import PageNotFound from './Pages/PageNotFound'
import Projects from './Pages/Projects';
import ContactMe from './Pages/ContactMe';
import Login from './Pages/Login';
import CallbackPage from './Resources/CallbackPage';
import Logout from './Resources/Logout';
import Minesweeper from './Pages/Minesweeper';

// Styles
import './Styles/WebsiteStyles.css';

// Images
import logo from './Images/logo-no-background.png';

function App() {
  return (
      <div className="background">
        <UserContextProvider>
          <BrowserRouter>
            <nav className="nav">
              <div>
                <img src={logo} alt="Website Logo" className="logo"/>
              </div>
              <div className="nav-links">
                <Link to="/">Home</Link>&nbsp;
                <Link to="/Projects">My Projects</Link>&nbsp;
                <Link to="/ContactMe">Contact Me</Link>&nbsp;
              </div>
              <div>
                <UserDetails/>
              </div>
            </nav>
            <br/>
            <Routes>
              <Route path='/' element={<Home/>}/>
              <Route path='/Projects' element={<Projects/>}/>
              <Route path='/ContactMe' element={<ContactMe/>}/>
              <Route path='/Login' element={<Login/>}/>
              <Route path="/auth/callback" element={<CallbackPage/>}/>
              <Route path="/Logout" element={<Logout/>}/>
              <Route path="/Minesweeper" element={<Minesweeper/>}/>
              <Route path='*' element={<PageNotFound/>}/>
            </Routes>
          </BrowserRouter>
        </UserContextProvider>
      </div>
  );
}

export default App;