import React from 'react';
import '../Styles/Projects.css';
import {useNavigate} from "react-router-dom"; // Import the CSS file

const Projects = () => {

    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleGameClick = () => {
        navigate('/Minesweeper');
    };


    return (
        <div className="projects-page">
            <section className="project-section" id="showcase">
                <h2>Project Showcase</h2>
                <div className="project-content">
                    <p>Learn about larger projects I've worked on in the corporate world.</p>
                </div>
            </section>
            <section className="project-section" id="game" onClick={handleGameClick}>
                <h2>Minesweeper</h2>
                <div className="project-content">
                    <p>Minesweeper coded in React.  High scores are stored for users who are logged in.</p>
                </div>
            </section>
            <section className="project-section" id="api-driven">
                <h2>API-Driven Project</h2>
                <div className="project-content">
                    {/* Insert your API-driven project component here */}
                    <p>Explore an API-driven application with dynamic data!</p>
                </div>
            </section>
        </div>
    );
};

export default Projects;
