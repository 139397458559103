import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import InfoBubble from '../Resources/InfoBubble';

function UserDetails() {
    const [displayName, setDisplayName] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Function to get the value of a cookie by name
        function getCookie(name) {
            const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) return match[2];
            return null;
        }

        // Try to fetch the token initially
        const token = getCookie('authToken');

        if (token) {
            try {
                const decoded = jwtDecode(token);
                setDisplayName(decoded.given_name); // Assuming 'given_name' is in the token payload
            } catch (error) {
                console.error('Invalid token:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            // Set a timeout to refetch the token after a short delay
            setTimeout(() => {
                const delayedToken = getCookie('authToken');
                if (delayedToken) {
                    try {
                        const decoded = jwtDecode(delayedToken);
                        setDisplayName(decoded.given_name);
                    } catch (error) {
                        console.error('Invalid token:', error);
                    }
                }
                setIsLoading(false);
            }, 500); // 500ms delay to allow time for the token to be set
        }
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="user-details-container">
            <div className="user-info">
                <InfoBubble text={"I don't use your information in any way,\n this is just for me to show off!"}/>
                <span className="user-name">
                    {displayName ? `Welcome, ${displayName}!` : <Link to="/Login">Login</Link>}
                </span>
            </div>
            {displayName ? <div className="logout-link">
                    <Link to="/Logout">Logout</Link>
            </div>
                : <></>
            }
        </div>
    );
}

export default UserDetails;
