import React, {useState, useCallback, useEffect} from 'react';
import '../Styles/Minesweeper.css';
import Fireworks from '../Resources/Fireworks'


const isValidIndex = (r, c, rows, cols) => {
    return (r >= 0 && r < rows && c >= 0 && c < cols);
}

const createBoard = (rows, cols) => {

    return Array.from({ length: rows < 4 ? 4 : rows }, () =>
        Array.from({ length: cols < 4 ? 4 : cols }, () => ({
            mine: false,
            revealed: false,
            adjacentMines: 0,
            flagged: false
        }))
    );
};

const setupBoard = (oldBoard, numMines, revealedRow, revealedCol, totalRows, totalCols) => {

    let newBoard = oldBoard;
    let minesPlaced = 0;

    newBoard[revealedRow][revealedCol].revealed = true;

    while (minesPlaced < numMines) {
        let row = Math.floor(Math.random() * totalRows);
        let col = Math.floor(Math.random() * totalCols);
        if (!newBoard[row][col].mine && !newBoard[row][col].revealed) {
            newBoard[row][col].mine = true;
            minesPlaced++;
        }
    }

    for (let i = 0; i < totalRows; i++) {
        for (let j = 0; j < totalCols; j++) {
            if (i > 0 && j > 0 && newBoard[i - 1][j - 1].mine) newBoard[i][j].adjacentMines++; // top left
            if (i > 0 && newBoard[i - 1][j].mine) newBoard[i][j].adjacentMines++; // top middle
            if (i > 0 && j < totalCols - 1 && newBoard[i - 1][j + 1].mine) newBoard[i][j].adjacentMines++; // top right
            if (j > 0 && newBoard[i][j - 1].mine) newBoard[i][j].adjacentMines++; // center left
            if (j < totalCols - 1 && newBoard[i][j + 1].mine) newBoard[i][j].adjacentMines++; // center right
            if (i < totalRows - 1 && j > 0 && newBoard[i + 1][j - 1].mine) newBoard[i][j].adjacentMines++; // bottom left
            if (i < totalRows - 1 && newBoard[i + 1][j].mine) newBoard[i][j].adjacentMines++; // bottom middle
            if (i < totalRows - 1 && j < totalCols - 1 && newBoard[i + 1][j + 1].mine) newBoard[i][j].adjacentMines++; // bottom right
        }
    }

    return newBoard;
};

const Minesweeper = () => {
    
    const [rows, setRows] = useState(10);
    const [cols, setCols] = useState(10);
    const [mines, setMines] = useState(20);
    const [gameOver, setGameOver] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [board, setBoard] = useState(createBoard(rows, cols));
    const [winner, setWinner] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function checkForWin(){
        let mines = 0
        let unrevealed = 0;
        board.forEach((row, rowIndex) => {
            board[rowIndex].forEach((col, colIndex) => {
                if(board[rowIndex][colIndex].mine && !board[rowIndex][colIndex].revealed) mines++;
                if(!board[rowIndex][colIndex].revealed || board[rowIndex][colIndex].flagged) unrevealed++;
            })
        })
        if (mines > 0 && mines === unrevealed) setWinner(true);
    }

    const handleClick = (row, col) => {

        if (gameOver) return;

        if (!gameStarted) {
            setBoard(setupBoard(board, mines, row, col, rows, cols));
            setGameStarted(true);
            setGameOver(false);
            setWinner(false);
        }

        if(!board[row][col].flagged){
            revealCell(row, col);
        }
    };

    const revealCell = useCallback((r, c) => {
        if (r < 0 || r >= rows || c < 0 || c >= cols || gameOver) return;

        let newBoard = board.map(row => row.map(cell => ({ ...cell })));
        newBoard[r][c].revealed = true;
        if(newBoard[r][c].mine) setGameOver(true);
        if(newBoard[r][c].adjacentMines === 0) {
            recursiveReveal(newBoard, r, c);
        }
        setBoard(newBoard);
        // eslint-disable-next-line
    }, [board, gameOver]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const recursiveReveal = (board, startRow, startCol) => {
        for(let i = startRow - 1; i <= startRow + 1; i++) {
            for(let j = startCol - 1; j <= startCol + 1; j++) {
                if(isValidIndex(i, j, rows, cols)){
                    if(!board[i][j].revealed && !board[i][j].mine){
                        board[i][j].revealed = true;
                        if(board[i][j].adjacentMines === 0) {
                            recursiveReveal(board, i, j);
                        }
                    }
                }
            }
        }
    }


    const flagCell = useCallback((r, c) => {
        if (r < 0 || r >= rows || c < 0 || c >= cols || gameOver) return;
        let newBoard = board.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
                if (rowIndex === r && colIndex === c) {
                    if(!cell.flagged) return { ...cell, flagged: true };
                    if(cell.flagged) return {...cell, flagged:false };
                }
                return cell;
            })
        );
        setBoard(newBoard);
        // eslint-disable-next-line
    }, [board, gameOver]);

    function handleRightClick(event, rowIndex, colIndex) {
        event.preventDefault();
        if (gameOver || !gameStarted || board[rowIndex][colIndex].revealed) return;
        flagCell(rowIndex, colIndex);
    }


    const handleNewGame = () => {
        setGameStarted(false);
        setGameOver(false);
        setWinner(false);
        setBoard(createBoard(rows, cols));
    }


    useEffect(() => {
        checkForWin();
        // eslint-disable-next-line
    }, [rows, cols, gameStarted, board]);

    return (
        <div className="minesweeper">
            <h1>Minesweeper</h1>
            <div className="game-panel">
                <div className="board-container">
                    <div className="board">
                        {board.map((row, rowIndex) => (
                            <div key={rowIndex} className="row">
                                {row.map((cell, colIndex) => (
                                    <div
                                        key={colIndex}
                                        className={`cell ${cell.revealed ? 'revealed' : ''}`}
                                        onClick={() => handleClick(rowIndex, colIndex)}
                                        onContextMenu={(e) => handleRightClick(e, rowIndex, colIndex)}
                                    >
                                        {cell.flagged ? '🚩' : cell.revealed ? (cell.mine ? '💣' : cell.adjacentMines > 0 ? cell.adjacentMines : '') : ''}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    {gameOver && <div className="game-over-message">Game Over!</div>}
                    {winner && gameStarted && <Fireworks/>}
                    {winner && gameStarted && <Fireworks/>}
                    {winner && gameStarted && <Fireworks/>}
            </div>
                <div className="options">
                    <button className="new-game-button" onClick={handleNewGame}>Start New Game</button><div className="settings">
                        <label>
                            Across:
                            <input
                                type="number"
                                value={rows}
                                onChange={(e) => setRows(Number(e.target.value))}
                                min="4"
                                max="15"
                                className="input-field"
                            />
                        </label>
                        <label>
                            Down:
                            <input
                                type="number"
                                value={cols}
                                onChange={(e) => setCols(Number(e.target.value))}
                                min="4"
                                max="20"
                                className="input-field"
                            />
                        </label>
                        <label>
                            Mines:
                            <input
                                type="number"
                                value={mines}
                                onChange={(e) => setMines(Number(e.target.value))}
                                min="1"
                                max={rows * cols - 1}
                                className="input-field"
                            />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Minesweeper;
